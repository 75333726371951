<template>
  <div>
    <the-breadcrumbs :custom-breadcrumbs="breadcrumbs"></the-breadcrumbs>
    <page-content page-title="Neuer Artikel">
      <article-form
        :article="article"
        submit-label="Neuen Artikel anlegen"
        :form-submitted="saveArticle"
        mode="new"
        @image-uploaded="addImage"
      ></article-form>
    </page-content>
  </div>
</template>

<script>
import Vue from "vue"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import ArticleForm from "@/components/forms/ArticleForm"
import ArticlesRepository from "@/repositories/articles_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Neuer Artikel"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    ArticleForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      article: {
        content: "",
        shared: false
      }
    }
  },
  computed: {
    persisted() {
      return this.article.hasOwnProperty("id")
    },
    breadcrumbs() {
      return [
        {
          path: "/guide",
          text: "Wissenswertes"
        },
        {
          path: `/article_collections/${this.$route.query.article_collection_id}`,
          text: `${this.$route.query.article_collection_name}`
        }
      ]
    }
  },
  async created() {
    this.article.collection_ids = [this.$route.query.article_collection_id]
  },
  methods: {
    async saveArticle(article) {
      if (this.persisted) {
        await ArticlesRepository.update(article.id, article)
      } else {
        await ArticlesRepository.create(article)
      }

      try {
        await this.$router.push({
          name: "GuidePage",
          query: { allow_redirect: true }
        })
      } catch {
        //
      }
    },
    async addImage({ article, signed_id, command }) {
      try {
        if (this.persisted) {
          article.images.push(signed_id)
          let response = await ArticlesRepository.update(article.id, article)
          Vue.set(this.article, "images", response.article.images)
          Vue.set(
            this.article,
            "image_attachments",
            response.article.image_attachments
          )
          let attachment = this.article.image_attachments.find(
            a => a.blob_signed_id === signed_id
          )
          command({
            src: `${process.env.VUE_APP_ROOT_API}/articles/${article.id}/get_image/${attachment.id}`
          })
        } else {
          article.images = []
          article.images.push(signed_id)
          let response = await ArticlesRepository.create(article)
          Vue.set(this.article, "id", response.article.id)
          Vue.set(this.article, "images", response.article.images)
          Vue.set(
            this.article,
            "image_attachments",
            response.article.image_attachments
          )
          let attachment = this.article.image_attachments.find(
            a => a.blob_signed_id === signed_id
          )
          command({
            src: `${process.env.VUE_APP_ROOT_API}/articles/${response.article.id}/get_image/${attachment.id}`
          })
        }
      } catch (error) {
        this.handleApiError(error)
      }
    }
  }
}
</script>
