<template>
  <div>
    <slot />
  </div>
</template>

<script>
// import "shepherd.js/dist/css/shepherd.css"
// import Shepherd from "shepherd.js"

export default {
  props: {
    name: {
      type: String,
      required: true
    },
    steps: {
      type: Array,
      required: true
    },
    highlightPadding: {
      type: Number,
      default: 15
    }
  },
  created() {
    // let toursDone = localStorage.getItem("familien-app-tours-done")
    // if (!toursDone) {
    //   localStorage.setItem("familien-app-tours-done", JSON.stringify({}))
    // }
  },
  mounted() {
    // let toursDone = JSON.parse(localStorage.getItem("familien-app-tours-done"))
    // if (!toursDone[this.name]) {
    //   let that = this
    //   this.$nextTick(() => {
    //     that.tour = new Shepherd.Tour({
    //       useModalOverlay: true,
    //       exitOnEsc: false
    //     })
    //     that.steps.forEach(function(step, i) {
    //       let computedStep = {
    //         ...{
    //           modalOverlayOpeningPadding: that.highlightPadding,
    //           scrollTo: true,
    //           buttons: that.getStepButtons(i, that.steps.length),
    //           scrollToHandler: that.scrollHandler
    //         },
    //         ...step
    //       }
    //       that.tour.addStep(computedStep)
    //     })
    //     that.tour.start()
    //   })
    // }
  },
  methods: {
    getStepButtons(index, totalSteps) {
      if (index === 0) {
        return [
          {
            text: "Abbrechen",
            classes: "shepherd-left-button",
            secondary: true,
            action: this.cancelTour
          },
          {
            text: "Weiter →",
            action: this.tour.next
          }
        ]
      } else if (index === totalSteps - 1) {
        return [
          {
            text: "← Zurück",
            secondary: false,
            action: this.tour.back
          },
          {
            text: "Tour abschließen",
            secondary: true,
            action: this.finishTour
          }
        ]
      } else {
        return [
          {
            text: "Abbrechen",
            secondary: true,
            classes: "shepherd-left-button",
            action: this.cancelTour
          },
          {
            text: "← Zurück",
            action: this.tour.back
          },
          {
            text: "Weiter →",
            action: this.tour.next
          }
        ]
      }
    },
    cancelTour() {
      let that = this
      this.tour.cancel()
      this.$confirm(
        "Möchten Sie diese Tour zu einem späteren Zeitpunkt fortsetzen?",
        "Tour abbrechen",
        {
          confirmButtonClass: "el-button--danger",
          confirmButtonText: "Nein, Tour nie mehr starten",
          cancelButtonText: "Ja",
          type: "warning"
        }
      )
        .then(async () => {
          that.dontShowTourAgain()
        })
        .catch(() => {})
    },
    dontShowTourAgain() {
      let toursDone = JSON.parse(
        localStorage.getItem("familien-app-tours-done")
      )
      toursDone[this.name] = true
      localStorage.setItem("familien-app-tours-done", JSON.stringify(toursDone))
    },
    finishTour() {
      this.tour.cancel()
      this.dontShowTourAgain()
    },
    scrollHandler(el) {
      if (el) {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop
        window.scrollTo({
          top: el.getBoundingClientRect().top + scrollTop - 80,
          left: 0,
          behavior: "smooth"
        })
      }
    }
  }
}
</script>
